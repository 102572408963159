import React, { Fragment } from 'react';

export default function MentionsLegales() {
  return <Fragment>
    <div className="container-mini">
      <h1>Mentions légales</h1>
      <div class="text-cg-wrapper">
        <p><strong><strong>&copy; Milan presse, 2020.</strong></strong> <strong>Directrice éditoriale&nbsp;:</strong> Aurélya Guerréro. <strong>Rédactrice en chef&nbsp;:</strong> Clara Recordier. <strong>Coordination du projet&nbsp;:</strong> Olivia Ensminger, Société d’Économie Alpestre de Haute-Savoie. <strong>Conception graphique et maquette&nbsp;:</strong> Nicolas Tirard, Myriam El Rharchi et Graphicat. <strong>Correction&nbsp;:</strong> Auriane Vigny. <strong>Responsable Web Presse/Édition&nbsp;:</strong> Virginie Vincent. <strong>Rédacteur en chef technique&nbsp;:</strong> Guylain Abbadie. <strong>1re rédactrice graphiste web&nbsp;:</strong> Arielle Krebs. <strong>Développeur&nbsp;:</strong> Nicolas Dhers.</p>
        <p><strong>Conte Pilou, à la rescousse ! </strong>&copy; Autrice&nbsp;: Pascale Hédelin, illustratrice&nbsp;: Nathalie Ragondet.</p>
        <p><strong>Poster Les alpages aux 4 saisons et memory des animaux </strong>&copy; Illustratrice&nbsp;: Armelle Drouin. </p>
        <p><strong>Les marottes et le décor </strong>&copy; Illustratrice&nbsp;: Nathalie Ragondet.</p>
        <p><strong>Les livrets pédagogiques&nbsp;:</strong> &copy; Autrice&nbsp;: Jocelyne Guégano, conseillère pédagogique départementale maternelle de l’Éducation nationale.</p>
        <p><strong>Partie Audio (chansons, sons, fiches pédagogiques)&nbsp;:</strong> &copy; Vox Alpina. Chanteurs&nbsp;: Nicolas Perrillat et Pierre Castellan. Enregistrement&nbsp;: Pierre Leloup.</p>
      </div>
      <div className="mentions-legales-randonneurs"><img src="/images/randonneurs.png" aria-hidden={true} alt="" /></div>
      <img src="/images/chien.png" className="perso-footer perso-footer-chien" aria-hidden={true} alt="" />
    </div>
  </Fragment>
}
